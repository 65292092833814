module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"addcraft Website","short_name":"addcraft","start_url":"/","background_color":"#005688","theme_color":"#005688","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89f5011b181fc935e37e3be7cd34f149"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-130488317-3","cookieName":"gdpr-consent","anonymize":true,"allowAdFeatures":true},"googleTagManager":{"trackingId":"GTM-TJHPGF3","cookieName":"gdpr-consent","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"1281290262265460","cookieName":"gdpr-consent"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
